export class CanvasHelper {
    static draw(ctx: CanvasRenderingContext2D, options: Object = {}, commands: any[]) {
        ctx.save();

        Object.keys(options).forEach((key) => {
            ctx[key] = options[key];
        });

        commands.forEach((cmd) => {
            if (cmd.type === 'line') {
                CanvasHelper.drawLine(ctx, cmd.path, cmd.fill, cmd.stroke);
            } else if (cmd.type === 'arc') {
                CanvasHelper.drawArc(ctx, cmd.x, cmd.y, cmd.radius, cmd.fill);
            } else if (cmd.type === 'rect') {
                CanvasHelper.drawRect(ctx, cmd.x, cmd.y, cmd.width, cmd.height, cmd.color);
            } else if (cmd.type === 'roundrect') {
                CanvasHelper.drawRoundRect(ctx, cmd.x, cmd.y, cmd.width, cmd.height, cmd.radius, cmd.color, cmd.stroke);
            } else if (cmd.type === 'text') {
                CanvasHelper.drawText(ctx, cmd.text, cmd.x, cmd.y, cmd.align, cmd.font, cmd.color);
            } else if (cmd.type === 'translate') {
                CanvasHelper.translate(ctx, cmd.x, cmd.y);
            } else if (cmd.type === 'rotate') {
                CanvasHelper.rotate(ctx, cmd.angle);
            }
        });

        ctx.restore();
    }

    static drawLine(ctx: CanvasRenderingContext2D, path: Object[], fill: boolean = false, stroke: boolean = true) {
        ctx.beginPath();

        path.forEach((coords: ICoordinates, idx) => {
            if (idx === 0) {
                ctx.moveTo(coords.x, coords.y);
            } else {
                ctx.lineTo(coords.x, coords.y);
            }
        });

        if (fill) {
            ctx.fill();
        }

        if (stroke) {
            ctx.stroke();
        }
    }

    static drawArc(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number, fill: boolean = false) {
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, Math.PI * 2);

        if (fill) {
            ctx.fill();
        }

        ctx.stroke();
    }

    static drawRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, color?: string) {
        ctx.save();
        ctx.beginPath();
        ctx.rect(x, y, width, height);

        if (color) {
            ctx.fillStyle = color;
            ctx.fill();
        }

        ctx.stroke();
        ctx.restore();
    }

    static drawRoundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: IBorderRadius = {
        tl: 5,
        tr: 5,
        br: 5,
        bl: 5
    }, color?: string, stroke: boolean = true) {
        const defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};

        for (let side in defaultRadius) {
            radius[side] = radius[side] || defaultRadius[side];
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (color) {
            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (stroke) {
            ctx.stroke();
        }

    }

    static drawText(ctx: CanvasRenderingContext2D, text: string, x: number, y: number, textAlign?: CanvasTextAlign, font?: string, color?: string) {
        ctx.save();
        if (textAlign) ctx.textAlign = textAlign;
        if (font) ctx.font = font;
        if (color) ctx.fillStyle = color;

        ctx.fillText(text, x, y);
        ctx.restore();
    }

    static translate(ctx: CanvasRenderingContext2D, x: number, y: number) {
        ctx.translate(x, y);
    }

    static rotate(ctx: CanvasRenderingContext2D, angle: number) {
        ctx.rotate(angle);
    }

    static wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let lines = 1;

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                lines++;
            } else {
                line = testLine;
            }
        }

        context.fillText(line, x, y);

        return lines;
    }

    static hexToRgbA(hex, opacity = 1) {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255, opacity].join(',') + ')';
        }
        throw new Error('Bad Hex');
    }
}

interface IBorderRadius {
    tl: number,
    br: number,
    bl: number,
    tr: number,
}

interface ICoordinates {
    x: number,
    y: number
}
