import {inject, customAttribute} from 'aurelia-framework';
import {RuntimeInfo} from "../classes/RuntimeInfo";

@customAttribute('datepicker')
@inject(Element)
export class DatePickerCustomAttribute {

    constructor(private element) {
    }

    attached() {
        ($(this.element) as any).datetimepicker({
            useCurrent: false,
            format: RuntimeInfo.DateTimeFormat,
            locale: RuntimeInfo.Language,
            showTodayButton: true,
            showClear: true,
            showClose: true,
            widgetPositioning: {
                horizontal: 'left',
                vertical: 'auto'
            },
            keepInvalid: true,
            focusOnShow: false
        }).change(() => {
            fireEvent(this.element, 'input')
        })
    }

    detached() {
        // remove it in here if possible!
    }
}

function fireEvent(element, name) {
    let event = document.createEvent('Event');
    event.initEvent(name, true, true);
    element.dispatchEvent(event);
}
